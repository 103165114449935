/* eslint no-console: off */

import aesjs from 'aes-js';
import { config } from './config';
import { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { ClientError, RequestExtendedOptions } from 'graphql-request';
import { WCLAppMwErrorResponse } from './requests/type';
import { CHINA_COUNTRY_CODE, PageTypeFromMP } from './constant';
import { GuestResponse } from './requests/guest';
import { CaptchaResponse } from '../components/GeetestCaptcha';

export const isDev = () => {
  return config.nodeEnv !== 'production';
};

interface TransformedError {
  isAxiosError: boolean;
  isGQLError: boolean;
  error: Error | AxiosError | ClientError;
}

export const transformToError = (err: unknown): TransformedError => {
  // string to Error
  if (typeof err === 'string') {
    return {
      isAxiosError: false,
      isGQLError: false,
      error: new Error(err),
    };
  }

  const error = err as Error;

  // Axios
  if ('isAxiosError' in error) {
    return {
      isAxiosError: true,
      isGQLError: false,
      error: error as AxiosError,
    };
  }

  // Graphql
  if (error instanceof ClientError) {
    return {
      isAxiosError: false,
      isGQLError: true,
      error: error as ClientError,
    };
  }

  // Normal Error
  return {
    isAxiosError: false,
    isGQLError: false,
    error: error as Error,
  };
};

const log = (level: string, tag: string, message: string, extra?: unknown) => {
  const logStr = `${level.toUpperCase()}\t [${tag}] ${message}`;
  extra ? console.log(logStr, extra) : console.log(logStr);
};

export const logger = {
  info: (tag: string, message: string, extra?: unknown) => {
    log('INFO', tag, message, extra);
  },
  debug: (tag: string, message: string, extra?: unknown) => {
    if (!isDev()) {
      return;
    }
    log('DEBUG', tag, message, extra);
  },
  error: (tag: string, message: string, err: unknown, onlyLogOnDev = false) => {
    if (onlyLogOnDev && !isDev()) {
      return;
    }
    const transformed = transformToError(err);
    if (transformed.isAxiosError) {
      // Axios
      const response = (transformed.error as AxiosError).response as AxiosResponse<WCLAppMwErrorResponse>;
      const error = response.data.error;
      log('ERROR', tag, message, {
        status: response.status,
        error,
      });
    } else if (transformed.isGQLError) {
      // Graphql
      const error = transformed.error as ClientError;
      log(
        'ERROR',
        tag,
        message,
        error.response.errors?.map((err) => {
          const copied = Object.assign({}, err);
          delete copied['path'];
          delete copied['locations'];
          return copied;
        })
      );
    } else {
      // Normal Error
      log('ERROR', tag, message, { error: transformed.error });
    }
  },
};

export enum MPRedirectType {
  CODE_EXPIRE_RETRY = 'CODE_EXPIRE_RETRY',
  CODE_EXPIRE_CANCEL = 'CODE_EXPIRE_CANCEL',
  LOGIN_DONE = 'LOGIN_DONE',
  BINDING_DONE = 'BINDING_DONE',
  BINDING_CONFLICT = 'BINDING_CONFLICT',
  BINDING_FAILED = 'BINDING_FAILED',
  OVERSEA_ACCOUNT = 'OVERSEA_ACCOUNT',
}

export const redirectBackToMP = (type: MPRedirectType, message: string, data?: unknown) => {
  logger.info('Util::redirectBackToMP', 'back to MP: variables', { type, message, data });
  const payload: { type: MPRedirectType; message: string; data?: string } = { type, message };

  const encryptionKey = getEncryptionKey() as Uint8Array;
  const params = window.urlParams.get('data') as string;
  const urlParams = aesDecrypt(encryptionKey, params) as UrlParams;

  const tobeEncrypted = {} as { passBack?: string; data?: unknown };
  if (urlParams.passBack) {
    tobeEncrypted.passBack = urlParams.passBack;
  }
  if (data !== undefined) {
    tobeEncrypted.data = data;
  }

  logger.debug('Util::redirectBackToMP', 'to be encrypted', { tobeEncrypted });
  if (Object.keys(tobeEncrypted).length > 0) {
    payload.data = aesEncrypt(encryptionKey, tobeEncrypted);
    logger.debug('Util::redirectBackToMP', 'encrypted data', { encrypted: payload.data });
  }

  logger.info('Util::redirectBackToMP', 'back to MP: payload', { payload });
  window.wx.miniProgram.postMessage({ data: payload });
  window.wx.miniProgram.navigateBack();
};

export const Storage = {
  set: (key: string, data: unknown) => {
    let item: string;
    if (data === undefined) {
      return;
    }
    if (typeof data === 'number') {
      item = data.toString();
    } else if (typeof data === 'object') {
      item = JSON.stringify(data);
    } else {
      item = String(data);
    }
    localStorage.setItem(key, item);
  },
  get: (key: string, needDecoding = true) => {
    const fromStorage = localStorage.getItem(key);
    if (fromStorage === null) {
      return null;
    }
    if (needDecoding) {
      try {
        return JSON.parse(fromStorage);
      } catch (err) {
        return fromStorage;
      }
    }
    return fromStorage;
  },
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};

export interface UrlParams {
  code: MPCode;
  passBack?: string;
  uid?: string;
  type?: PageTypeFromMP;
}

export interface MPCode {
  code: string;
  expire: number; // Date.now()
}

export const isNumeric = (num: string) => {
  if (num.trim() === '') {
    return false;
  }
  return !isNaN(num as unknown as number);
};

export const openUrlWin = (url: string) => {
  window.open(url, '_blank');
};

export const genCurlFromAxiosOptions = (options: AxiosRequestConfig) => {
  const headers = [];
  for (const [key, value] of Object.entries(options.headers as AxiosRequestHeaders)) {
    headers.push(`-H '${key}: ${value}'`);
  }
  let command = `curl -X ${options.method ? options.method.toUpperCase() : 'POST'}`;
  if (headers.length > 0) {
    command += ` ${headers.join(' ')}`;
  }
  if (options.data) {
    command += ` -d '${JSON.stringify(options.data)}'`;
  }
  return command + ` ${options.baseURL}${options.url}`;
};

export const genCurlFromGQLOptions = (options: RequestExtendedOptions) => {
  const headers = [];
  for (const [key, value] of Object.entries(options.requestHeaders as Record<string, string>)) {
    headers.push(`-H '${key}: ${value}'`);
  }
  let command = `curl -X POST`;
  if (headers.length > 0) {
    command += ` ${headers.join(' ')}`;
  }
  const data = {} as { query: string; variables?: Record<string, unknown> };
  data.query = options.document.toString();
  if (options.variables) {
    data.variables = options.variables;
  }
  command += ` -d '${JSON.stringify(data)}'`;

  return command + ` ${options.url}`;
};

export const getEncryptionKey = (): Uint8Array | undefined => {
  // "key" should be a json string of chars: '[2,1,33,4]'
  const key = window.urlParams.get('key');
  if (key) {
    try {
      const parsedKey = Uint8Array.from(JSON.parse(key) as number[]);
      logger.info('Util::getEncryptionKey', 'encryption key', { key, parsedKey });
      return parsedKey;
    } catch (err) {
      logger.error('Util::getEncryptionKey', 'error when JSON.parse url param "key"', { key });
    }
  } else {
    logger.error('Util::getEncryptionKey', 'no key provided on page', 'no key');
  }
};

/**
 * copied from https://jira.hilton.com.cn/stash/projects/CHIN/repos/hiltoncn-wechat-mp/pull-requests/1075/commits/eb71ba708c18daffcacc763bf410005f698b69fa?since=55f0094722dc7ce658c0358536a3e270cf858092#src/utils/aesEncrypt.js
 */
export const aesEncrypt = (key: Uint8Array, data: unknown) => {
  if (!key) {
    throw new Error('aesEncrypt: missing required key');
  }
  const aesCTR = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));

  const value = JSON.stringify(data);
  const valueBytes = aesjs.utils.utf8.toBytes(value);
  const encryptedBytes = aesCTR.encrypt(valueBytes);
  return aesjs.utils.hex.fromBytes(encryptedBytes);
};

export const aesDecrypt = (key: Uint8Array, data: string) => {
  if (!key) {
    throw new Error('aesDecrypt: missing required key');
  }
  const aesCTR = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
  const encryptedBytes = aesjs.utils.hex.toBytes(data);
  const decryptedBytes = aesCTR.decrypt(encryptedBytes);
  const decrypted = aesjs.utils.utf8.fromBytes(decryptedBytes);
  return JSON.parse(decrypted);
};

export const isOverseaAccount = (guestRes: GuestResponse | undefined) => {
  if (guestRes === undefined) {
    // do not block normal business logic if guest request failed or no data fetched
    return false;
  }
  if (guestRes.guest.personalinfo.addresses.length === 0) {
    // same here, do not block normal logic
    return false;
  }
  return guestRes.guest.personalinfo.addresses[0].country !== CHINA_COUNTRY_CODE;
};

export const generateCaptchaToken = (payload: CaptchaResponse) => {
  return btoa(
    JSON.stringify({
      provider: 'GEETEST',
      version: config.geetestVersion,
      data: payload,
    })
  );
};
