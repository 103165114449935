import { Fragment } from 'react';
import App, { AppContext } from 'next/app';
import type { AppProps } from 'next/app';
import Layout from '../components/Layout/index';
import './styles/global.css';
import { config } from '../lib/config';
import { logger } from '../lib/util';
import { withRouter } from 'next/router';
import Head from 'next/head';

const SocialLoginUIApp = ({ Component, pageProps, router }: AppProps) => {
  if (router.constructor.name === 'ServerRouter') {
    logger.info('App::route', 'page view', JSON.stringify({ route: router.route, query: router.query }));
  }

  return (
    <Fragment>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </Fragment>
  );
};

SocialLoginUIApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const { pageProps } = appProps;
  const configMerged = { ...pageProps, config };
  logger.debug('_app::getInitialProps', 'pageProps', { pageProps: JSON.stringify(configMerged) });

  return { pageProps: configMerged };
};

export default withRouter(SocialLoginUIApp);
