import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import styles from '../Shared/styles/Shared.module.css';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: NextPage<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <div className={styles.container}>
      <Head>
        <title>Social Login UI</title>
      </Head>
      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default Layout;
