export const PLATFORM_WECHAT = 'WECHAT';

export const TOTP_EXPIRE_DURATION_DISPLAY = 10; // min
export const VALIDATING_SMS_COOL_DOWN = 60; // second
export const PASSWORD_REGEX =
  /^(?=.*[\d~!@#$%^&*()_+\-=[{\]};:'",<.>/?])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d~!@#$%^&*()_+\-=[{\]};:'",<.>/?]{8,}$/;
export const PHONE_NUMBER_CN_REGEX = /^1[\d]{10}$/;
export const VALIDATING_SMS_CODE_REGEX = /^[\d]{6}$/;
export const CHINA_COUNTRY_CODE = 'CN';
export const BINDING_SMS_CODE_LENGTH = 6;

export enum PageTypeFromMP {
  ENROLL = 'ENROLL',
  LOGIN = 'LOGIN',
  BIND = 'BIND',
}

export enum BindResponseCodes {
  DONE = 'DONE',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  VALIDATION_REQUIRED = 'VALIDATION_REQUIRED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  NO_VALIDATION_METHOD = 'NO_VALIDATION_METHOD',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  CONFLICT = 'CONFLICT',
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
}

export enum AppMessage {
  JS_CODE_EXPIRED = 'JS_CODE_EXPIRED',
  LOGIN_AUTH_FAILED = 'LOGIN_AUTH_FAILED',
  LOGIN_USERNAME_INVALID = 'LOGIN_USERNAME_INVALID',
  LOGIN_PASSWORD_LENGTH_INVALID = 'LOGIN_PASSWORD_LENGTH_INVALID',
  LOGIN_PASSWORD_FORMAT_INVALID = 'LOGIN_PASSWORD_FORMAT_INVALID',
  LOGIN_TOO_MANY_SMS_ATTEMPTS = 'LOGIN_TOO_MANY_SMS_ATTEMPTS',
  LOGIN_SMS_CODE_VALIDATION_WITHOUT_SEND = 'LOGIN_SMS_CODE_VALIDATION_WITHOUT_SEND',
  SEND_SMS_INVALID_PHONE = 'SEND_SMS_INVALID_PHONE',
  SEND_SMS_TOO_FREQUENT = 'SEND_SMS_TOO_FREQUENT',
  BINDING_CODE_INVALID = 'BINDING_CODE_INVALID',
  BINDING_CONFLICT = 'BINDING_CONFLICT',
  BINDING_TOO_MANY_ATTEMPTS = 'BINDING_TOO_MANY_ATTEMPTS',
  BINDING_TOO_MANY_REQUESTS = 'BINDING_TOO_MANY_REQUESTS',
  BINDING_DONE = 'BINDING_DONE',
  BINDING_FAILED = 'BINDING_FAILED',
  NO_VALIDATION_METHOD = 'NO_VALIDATION_METHOD',
  OVERSEA_ACCOUNT = 'OVERSEA_ACCOUNT',
  DEFAULT = 'DEFAULT',
}
